<template>
  <div v-if="loading" class="loading">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import { useStore } from 'vuex';

export default defineComponent({
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  }
})
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

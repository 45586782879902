<template>
  <div class="col">
    <div class="row">
      <div class="col">
        <v-autocomplete class="employee" label="Kies medewerker" :items="employees" :disabled="!employees || isEss"
          item-title="Naam" item-value="Medewerker" @update:modelValue="onChangeEmployee"
          v-if="employees && employees.length > 0 && !isEss && !fromProject" auto-select-first>
        </v-autocomplete>
        <v-autocomplete class="employee" label="Kies project" :items="projects" :disabled="!projects"
          item-title="Projectomschrijving" item-value="ProjectCode" @update:modelValue="onChangeProject"
          v-if="projects && projects.length > 0 && fromProject" auto-select-first>
        </v-autocomplete>
        <VDatePicker color="white" :attributes="attrs" @dayclick="onClickDay" :columns="amountColumns" :rows="amountRows"
          showWeeknumbers :disabledDates="disabledDates" :step="3" />
      </div>
      <div class="col">
        <table>
          <thead>
            <th>Datum</th>
            <th>Status</th>
          </thead>
          <tbody>
            <tr v-for="newdate in newDates">
              <td>{{ newdate.description }}</td>
              <v-icon icon="mdi:mdi-border-radius" v-if="newdate.status === Status.init"></v-icon>
              <v-progress-circular indeterminate v-if="newdate.status === Status.loading"></v-progress-circular>
              <v-icon color='success' icon="mdi:mdi-content-save-check-outline" v-if="newdate.status === Status.saved"></v-icon>
              <v-tooltip start text="tooltip">
                <template v-slot:activator="{ props }">
                  <v-icon icon="mdi:mdi-alert-circle-outline" color="error" v-if="newdate.status === Status.error"
                    v-bind="props"></v-icon>
                </template>
                <span>{{ newdate.error }}</span>
              </v-tooltip>
            </tr>
          </tbody>
        </table>
        <div v-if="newDates && newDates.length > 0" class="totals">
          Aantal dagen: {{ newDates.length }}
        </div>
        <v-btn color="success" :disabled="!newDates || newDates.filter((d: any) => d.status !== 'saved' && d.status !== 'loading').length === 0" class="mt-4" block @click="dialog = true">
          Opslaan in AFAS
        </v-btn>
      </div>
    </div>
    <v-alert v-if="stateError" type="error" title="Error" :text="stateError"> </v-alert>
  </div>

  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-text>
        <div class="col">
          <v-autocomplete label="Kies project" :items="projects" :disabled="!projects" item-title="Projectomschrijving"
            item-value="ProjectCode" @update:modelValue="onChangeProject" v-if="projects && projects.length > 0 && !fromProject">
          </v-autocomplete>
          <v-autocomplete label="Kies medewerker" :items="employees" :disabled="!employees" item-title="Naam"
            item-value="Medewerker" @update:modelValue="onChangeEmployee" v-if="employees && employees.length > 0 && fromProject">
          </v-autocomplete>
          <v-autocomplete :label="(!projectFases || projectFases.length === 0) ? 'Geen projectfase' : 'Kies projectfase'" 
            :items="projectFases" :disabled="!projectFases || projectFases.length == 0" 
            item-title="Omschrijving"
            item-value="Projectfase" 
            @update:modelValue="onChangeProjectFase" v-if="useProjectFase">
          </v-autocomplete>
          <v-autocomplete label="Kies itemcode" :items="items" :disabled="!items" item-title="ItemOmschrijving"
            item-value="Itemcode" @update:modelValue="onChangeItem" v-if="items && items.length > 0">
          </v-autocomplete>
          <v-text-field id="begintime" label="Begintijd" type="time" v-model="begintime"></v-text-field>
          <v-text-field id="endtime" label="Eindtijd" type="time" v-model="endtime"></v-text-field>
          <v-text-field if="description" label="Omschrijving" type="text" v-model="description"></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" @keyup.esc="dialog = false" tabindex="-1">
          Annuleren
        </v-btn>
        <v-btn color="success" :disabled="!selectedProject || !selectedItem" @click="saveToAFAS">
          Opslaan
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showDatePopup" width="60%" @keyup.esc="setShowDatePopup(false)" tabindex="-1">
    <v-card>
      <v-card-item>
        <v-card-title>{{ datePopupDates.length > 0 
                        ? DateTime.fromISO(datePopupDates[0]?.Datum).toFormat('yyyy-MM-dd')
                        : DateTime.now().toFormat('yyyy-MM-dd') }}</v-card-title>
      </v-card-item>
      <v-card-text>
        <v-table>
          <thead>
            <tr>
              <th>Omschrijving</th>
              <th>Aantal uur</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="date in datePopupDates">
              <td>{{ date.ProjectOmschrijving }} - {{ date.ItemOmschrijving }}</td>
              <td>{{ date.Aantal }}</td>
              <td>
                <div class="actions">
                  <v-btn icon="mdi-file-edit" color="light-blue" size="small" @click="openEditNacalc(date)" :disabled="storeEditLoading"></v-btn> &nbsp;
                  <v-btn icon="mdi-delete-alert" color="red-darken-2" size="small" @click="openDeleteNacalc(date)" :disabled="storeEditLoading"></v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-progress-circular indeterminate v-if="storeEditLoading"></v-progress-circular>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon="mdi-plus-circle" color="green" size="x-large" @click="addDate(DateTime.fromISO(datePopupDates[0]?.Datum))"  ></v-btn>
        <v-btn @click="setShowDatePopup(false)">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showDeleteNacalcDialogComputed" width="40%" @keyup.esc="showDeleteNacalcDialog = false" tabindex="-1">
    <v-card>
      <v-card-title>Verwijderen</v-card-title>
      <v-card-text>
        Weet je zeker dat je de nacalculatieregel wilt verwijderen?<br />
        <v-progress-circular indeterminate v-if="storeEditLoading"></v-progress-circular>
        <p>
          Datum: {{ DateTime.fromISO(nacalcToEdit.Datum).toFormat("yyyy-MM-dd") }}<br/>
          Project: {{ nacalcToEdit.ProjectOmschrijving }}<br/>
          Item: {{ nacalcToEdit.ItemOmschrijving }}<br/>
          Aantal uur: {{ nacalcToEdit.Aantal }}<br/>
        </p>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-progress-circular indeterminate v-if="storeEditLoading"></v-progress-circular>
        <v-btn @click="deleteNacalc" color="red">Verwijderen</v-btn>
        <v-btn @click="showDeleteNacalcDialog = false">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showUpdateNacalcDialogComputed" width="60%" @keyup.esc="setShowNacalcUpdatePopup(false)" tabindex="-1">
    <v-card>
      <v-card-title>Aanpassen</v-card-title>
      <v-card-text>
        <v-autocomplete label="Kies project" :items="projects" :disabled="!projects" item-title="Projectomschrijving"
            item-value="ProjectCode" v-if="projects && projects.length > 0 && !fromProject" v-model:model-value="nacalcToEdit.ProjectId">
          </v-autocomplete>
          <v-autocomplete label="Kies medewerker" :items="employees" :disabled="!employees" item-title="Naam"
            item-value="Medewerker" v-if="employees && employees.length > 0 && fromProject" v-model:model-value="nacalcToEdit.MedewerkerCode">
          </v-autocomplete>
          <v-autocomplete :label="(!projectFases || projectFases.length === 0) ? 'Geen projectfase' : 'Kies projectfase'" :items="projectFases" :disabled="!projectFases || projectFases.length == 0" item-title="Omschrijving"
            item-value="Projectfase" v-if="useProjectFase" v-model:model-value="nacalcToEdit.Projectfase">
          </v-autocomplete>
          <v-autocomplete label="Kies itemcode" :items="items" :disabled="!items" item-title="ItemOmschrijving"
            item-value="Itemcode" v-if="items && items.length > 0" v-model:model-value="nacalcToEdit.Itemcode">
          </v-autocomplete>
          <v-text-field id="begintime" label="Begintijd" type="time" v-model="nacalcToEdit.Begintijd"></v-text-field>
          <v-text-field id="endtime" label="Eindtijd" type="time" v-model="nacalcToEdit.Eindtijd"></v-text-field>
          <v-text-field if="description" label="Omschrijving" type="text" v-model="nacalcToEdit.Omschrijving"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-progress-circular indeterminate v-if="storeEditLoading"></v-progress-circular>
        <v-btn @click="updateNacalc" color="green">Opslaan</v-btn>
        <v-btn @click="setShowNacalcUpdatePopup(false)">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showOTPCodeDialog" width="60%">
    <v-card>
      <v-card-title>OTP code invoeren</v-card-title>
      <v-card-text>
          <p>Vul hier de code in die naar je emailadres is verzonden om Simplex PLNNR te activeren.</p>
          <v-text-field if="description" label="Activatiecode" type="text" v-model="otpcode"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-progress-circular indeterminate v-if="storeEditLoading"></v-progress-circular>
        <v-btn @click="sendOTPCode" color="green">Activeren</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

<v-snackbar v-model="showSnackbar">
  {{ deleteOrUpdateError }}
  <template><v-btn color="red" variant="text" @click="setSnackbar(false)">Sluiten</v-btn></template>
</v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Status } from '../enums/realizationstatus'
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'SimplexPLNNR',
  setup() {
    return { Status, DateTime };
  },
  data() {
    return {
      store1: this.$store,
      amountColumns: 3,
      amountRows: 2,
      begintime: '09:00',
      endtime: '17:00',
      description: '',
      today: new Date(),
      dialog: false,
      showerror: false,
      nacalcToEdit: {} as any,
      showDeleteNacalcDialog: false,
      showEditNacalcDialog: false,
      showSnackbar: false,
      otpcode: '' as string
    }
  },
  watch: {
    description() {
      this.$store.dispatch('setDescription', this.description)
    },
    storedescription() {
      this.description = this.$store.state.description
    }
  },
  computed: {
    stateError(): string {
      return this.$store.state.error
    },
    showSnackbar(): Boolean {
      return this.$store.state.showSnackbar
    },
    deleteOrUpdateError() {
      return this.$store.state.deleteOrUpdateError
    },
    showOTPCodeDialog() {
      return this.$store.state.showOTPCodePopup
    },
    showDeleteNacalcDialogComputed() {
      return this.showDeleteNacalcDialog && this.$store.state.datePopupDates.length > 0
    },
    showUpdateNacalcDialogComputed() {
      return this.$store.state.showNacalcUpdatePopup
    },
    storeEditLoading() {
      return this.$store.state.deleteOrEditLoading
    },
    datePopupDates() {
      return this.$store.state.datePopupDates
    },
    showDatePopup() {
      return this.$store.state.showDatePopup
    },
    newDates() {
      return this.$store.state.newDates
    },
    employees() {
      return this.$store.state.employees
    },
    nacalculatie() {
      return this.$store.state.nacalculatie
    },
    projects() {
      return this.$store.state.projects
    },
    items() {
      return this.$store.state.items
    },
    attrs() {
      return this.$store.state.attrs
    },
    disabledDates() {
      return this.$store.state.disabledDates
    },
    error() {
      return this.$store.state.error
    },
    selectedProject() {
      return this.$store.state.selectedProject
    },
    selectedItem() {
      return this.$store.state.selectedItem
    },
    storedescription() {
      return this.$store.state.description
    },
    isEss() {
      return this.$store.state.ess
    },
    fromProject() {
      return this.$store.state.fromProject
    },
    useProjectFase() {
      return this.$store.state.useProjectFase
    },
    projectFases() {
      return this.$store.state.projectFases
    }
  },
  methods: {
    setShowNacalcUpdatePopup(bool: any) {
      this.$store.dispatch('setShowNacalcUpdatePopup', bool)
    },
    setSnackbar(bool: any) {
      this.$store.dispatch('setSnackbar', bool)
    },
    setShowDatePopup(bool: any) {
      this.$store.dispatch('setShowDatePopup', bool)
    },
    async sendOTPCode() {
      this.$store.dispatch('sendOTPCode', this.otpcode)
    },
    async deleteNacalc() {
      this.$store.state.deleteOrEditLoading = true;
      this.$store.dispatch('deleteNacalc', this.nacalcToEdit)
      this.showDeleteNacalcDialog = false;
    },
    async updateNacalc() {
      this.$store.state.deleteOrEditLoading = true;
      this.$store.dispatch('updateNacalc', this.nacalcToEdit)
      this.showEditNacalcDialog = false;
    },
    openDeleteNacalc(nacalc: any) {
      this.nacalcToEdit = nacalc
      this.showDeleteNacalcDialog = true;
    },
    openEditNacalc(nacalc: any) {
      this.nacalcToEdit = nacalc
      this.$store.state.showNacalcUpdatePopup = true;
    },
    onClickDay(day: any) {
      this.$store.commit('clickDate', day)
    },
    addDate(date: DateTime) {
      this.$store.commit('addDate', {date: date.toJSDate()})
    },
    onChangeEmployee(employee: any) {
      this.$store.dispatch('setEmployee', { employee: employee, resetDates: !this.fromProject })
    },
    onChangeProject(project: any) {
      this.$store.dispatch('setProject', { project: project, resetDates: this.fromProject })
      if (this.useProjectFase) {
        this.$store.dispatch('getProjectFases')
      }
    },
    onChangeProjectFase(fase: any) {
      this.$store.dispatch('setProjectFase', fase);
    },
    onChangeItem(item: any) {
      this.$store.dispatch('setItem', item)
    },
    onChangeDescription(description: any) {
      this.$store.dispatch('setDescription', description)
    },
    saveToAFAS() {
      this.$store.dispatch('saveToAFAS', { begintime: this.begintime, endtime: this.endtime });
      this.dialog = false;
    },

  },
  mounted() {
    const [_, query] = window.location.href.split('#')[1].split('?')
    const { publickey } = Object.fromEntries(new URLSearchParams(query))

    if (!this.$store.state.employees) {
      this.$store.dispatch('getEmployees')
    }
    if (!this.$store.state.projects) {
      this.$store.dispatch('getProjects')
    }
    if (!this.$store.state.items) {
      this.$store.dispatch('getItems')
    }
  }
});
</script>

<style lang="scss">
.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.employee {
  max-width: 500px;
}

.totals {
  align-items: start;
  padding-top: 20px;
}

th {
  padding: 10px;
}
.v-input {
  width: 100%;
}

.v-card-actions {
  justify-content: flex-end;
}
.v-alert {
  margin: 25px;
}
.actions {
  min-width: 100px;
  width: 100px;
}
</style>
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue')
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to.path)
  if (to.path === '/') {
    console.log(store.state.sessionId)
    if (!store.state.sessionId) {
      const e = await store.dispatch('login', to.query);
      console.log(e)
      if (e === 401) {
        router.push('/unauthorized')
        return;
      } else if (e === 403) {
        router.push('/forbidden');
        return;
      }
    }
  }
  next()
});

export default router

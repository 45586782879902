import { Store, createStore } from 'vuex'
import axios from 'axios';
import { DateModel } from '@/models/dateModel';
import { exist } from 'joi';
import { DateTime } from 'luxon';
import luxon from 'luxon'
import _ from 'lodash';
import { Status } from '../enums/realizationstatus'

let API_URL = 'https://plnnr.simplexconnect.nl';
if (process.env.NODE_ENV === 'development') {
    API_URL = 'http://localhost:3000'
}
if (window.location.href.includes('simplexplnnr-dev')) {
  API_URL = 'https://simplexplnnr-dev.azurewebsites.net'
}

const green = '#1AA38C'
const orange = '#F99300'
const blue = '#028CCA'
const red = '#D32F2F'

axios.interceptors.request.use(async (config) => {
  if (!config || !config.url || !config.url.endsWith('/login') ) {
    if (!config?.url?.endsWith('/settings') && !config?.url?.endsWith('settingslogin') && !config?.url?.endsWith('settingsloadinstances') && !config?.url?.endsWith('settingssaveinstance')) {
      await store.state.loginPromise;
    }
  }
  if (store.state.sessionId && config.headers) {
    config.headers['session-id'] = store.state.sessionId;
  }
  return config;
});

let resolveLoginPromise: any;

const store =  createStore({
  state: {
    loginPromise: new Promise((res) => resolveLoginPromise = res),
    sessionId: null as string | null,
    environment: null as string | null,
    employees: null as Array<any> | null,
    items: null as Array<any> | null,
    nacalculatie: [] as Array<any>,
    projects: null as Array<any> | null,
    newDates: [] as Array<DateModel>,
    loading: 0 as number,
    currentEmployee: null as string | null,
    attrs: [] as Array<any>,
    disabledDates: [] as any,
    error: '' as string,
    selectedProject: null as string | null,
    selectedItem: null as string | null,
    selectedProjectFase: null as string | null,
    description: null as string | null,
    settingsAccessToken: null as any | null,
    instances: null as Array<any> | null,
    ess: false as Boolean,
    showDatePopup: false as Boolean,
    datePopupDates: [] as Array<any>,
    snackbarMessage: '' as string,
    showSnackbar: false as Boolean,
    deleteOrEditLoading: false as Boolean,
    showNacalcUpdatePopup: false as Boolean,
    showOTPCodePopup: false as Boolean,
    fromProject: false as Boolean,
    colors: [] as Array<any> || null,
    timeFromError: 16 as number,
    timeToPartial: 4 as number,
    useProjectFase: false as Boolean,
    projectFases: [] as Array<any> || null,
    dimension1: null as string | null
  },
  getters: {
  },
  mutations: {
    addDate(state, payload) {
      let newDateModel = {
        date: payload.date, 
        description: `${(payload.date.getDate().toString()).padStart(2, '0')}-${((payload.date.getMonth() + 1).toString()).padStart(2, '0')}-${payload.date.getFullYear()}`, 
        status: Status.init} as DateModel;
      state.newDates.push(newDateModel);
      state.newDates = state.newDates.sort((a, b) => {
        return a.date < b.date ? -1 : 1;
      });
      state.error = ''
      // make date green
      let attr = state.attrs.filter(x =>  {
          return (
                   x.dates.getFullYear() === payload.date.getFullYear() 
                && x.dates.getMonth() === payload.date.getMonth()
                && x.dates.getDate() === payload.date.getDate()
                )
        }
      );
      if (attr && attr.length) {
        attr[0].highlight.style.background = green;
        attr[0].highlight.style['background-color'] = green
      }
      state.showDatePopup = false
    },
    clickDate(state, payload) {
      if (!state.currentEmployee && !state.fromProject) {
        state.error = 'Kies eerst een medewerker';
        return;
      }
      if (!state.selectedProject && state.fromProject) {
        state.error = 'Kies eerst een project';
        return;
      }
      let existing = state.newDates?.filter(x => {
        return x.date.getFullYear() === payload.date.getFullYear() 
            && x.date.getMonth() === payload.date.getMonth()
            && x.date.getDate() === payload.date.getDate()
      });
      if (existing && existing.length > 0) { // delete date
        // remove from dates
        state.newDates = state.newDates?.filter(x => {
          return x.date.getFullYear() !== payload.date.getFullYear() 
            || x.date.getMonth() !== payload.date.getMonth()
            || x.date.getDate() !== payload.date.getDate()
        }).sort((a, b) => {
          return a.date < b.date ? -1 : 1;
        });
        // remove from calendar
        state.attrs = state.attrs.filter(x =>  {
          return (x.highlight.style.background === orange
              || x.highlight.style.background === blue
              ||  (    x.dates.getFullYear() !== payload.date.getFullYear() 
                    || x.dates.getMonth() !== payload.date.getMonth()
                    || x.dates.getDate() !== payload.date.getDate()
                  )
              || x.amount
            )
        })
        // set back to blue or orange when necessary
        let attr = state.attrs.filter(x =>  {
          return (
                   x.dates.getFullYear() === payload.date.getFullYear() 
                && x.dates.getMonth() === payload.date.getMonth()
                && x.dates.getDate() === payload.date.getDate()
                )
          }
        );
        if (attr && attr.length) {
          if (attr[0].amount) {
            let colorToSet = blue;
            if (attr[0].amount <= state.timeToPartial) {
              colorToSet = orange;
            } else if (attr[0].amount >= state.timeFromError) {
              colorToSet = red;
            }
            attr[0].highlight.style.background = colorToSet;
            attr[0].highlight.style['background-color'] = colorToSet;
          }
          setColorsForItemCodes(state)
        }

        state.error = ''
      } else { // add date
        // first check on existing date in calendar
        let existing = state.attrs.filter(x => {
          return (
            (
                 x.dates.getFullYear() === payload.date.getFullYear() 
              && x.dates.getMonth() === payload.date.getMonth()
              && x.dates.getDate() === payload.date.getDate()
            ) 
            && 
            (
              x.hasnacalc == true
            )
          )
        });
        if (existing && existing.length > 0) {
          state.datePopupDates = state.nacalculatie?.filter(x => {
            return (DateTime.fromISO(x.Datum).year === payload.date.getFullYear() 
                && DateTime.fromISO(x.Datum).month === payload.date.getMonth() + 1
                && DateTime.fromISO(x.Datum).day === payload.date.getDate())
          });
          state.showDatePopup = true;
          return;
        }
        let newDateModel = {
          date: payload.date, 
          description: `${(payload.date.getDate().toString()).padStart(2, '0')}-${((payload.date.getMonth() + 1).toString()).padStart(2, '0')}-${payload.date.getFullYear()}`, 
          status: Status.init} as DateModel;
        state.newDates.push(newDateModel);
        state.newDates = state.newDates.sort((a, b) => {
          return a.date < b.date ? -1 : 1;
        });
        state.error = ''
        // add to calendar
        state.attrs.push({
          key: DateTime.fromJSDate(payload.date).toFormat('yyyy-MM-dd'),
          highlight: {
            style: {
              background: green,
              'background-color': green
            },
            contentStyle: {
              color: 'White'
            },
            fillMode: 'solid'
          },
          dates: payload.date
        })
      }
    }
  },
  actions: {
    async login(ctx, payload) {
      ctx.state.loginPromise = new Promise((res) => resolveLoginPromise = res);
      try {
        const res = await axios.post(`${API_URL}/login`, payload);
        ctx.state.sessionId = res.data.sessionId;
        ctx.state.ess = res.data.ess;
        ctx.state.colors = res.data.colors;
        ctx.state.fromProject = res.data.fromProject;
        ctx.state.useProjectFase = res.data.useProjectFase;

        if (res.data.showOtpDialog) {
          ctx.state.showOTPCodePopup = true;
        }
        if (res.data.timeFromError) {
          const hours = res.data.timeFromError.split(':')[0] * 1;
          const minutes = res.data.timeFromError.split(':')[1] * 1;
          ctx.state.timeFromError = Math.round((hours + (minutes / 60)) * 60) / 60
        }
        if (res.data.timeToPartial) {
          const hours = res.data.timeToPartial.split(':')[0] * 1;
          const minutes = res.data.timeToPartial.split(':')[1] * 1;
          ctx.state.timeToPartial = Math.round((hours + (minutes / 60)) * 60) / 60
        }
      } catch (e: any) {
        console.error(e)
        return e.response.status
      }
      resolveLoginPromise();
    },
    async getEmployees(ctx) {
      if (ctx.state.showOTPCodePopup) {
        return;
      }
      ctx.state.loading++
      const res = await axios.get(`${API_URL}/getEmployees`)
      ctx.state.employees = res.data;
      if (ctx.state.ess) {
        ctx.dispatch('setEmployee', { employee: res.data[0].Medewerker, resetDates: true })
      }
      ctx.state.loading--
    },
    async getItems(ctx) {
      if (ctx.state.showOTPCodePopup) {
        return;
      }
      ctx.state.loading++
      const res = await axios.get(`${API_URL}/getItems`)
      ctx.state.items = res.data;
      ctx.state.loading--
    },
    async getProjects(ctx) {
      if (ctx.state.showOTPCodePopup) {
        return;
      }
      ctx.state.loading++
      const res = await axios.get(`${API_URL}/getProjects`)
      ctx.state.projects = res.data;
      ctx.state.loading--;
    },
    async getProjectFases(ctx) {
      if (ctx.state.showOTPCodePopup) {
        return;
      }
      ctx.state.loading++;
      const res = await axios.get(`${API_URL}/getProjectFases?projectId=${ctx.state.selectedProject}`)
      ctx.state.projectFases = res.data;
      ctx.state.loading--;
    },
    async setProject(ctx, payload) {
      ctx.state.selectedProject = payload.project
      if (ctx.state.projects) {
        for (let project of ctx.state.projects) {
          if (project.ProjectCode === payload.toString()) {
            ctx.state.description = project.Relatienaam;
          }
        }
      }
      // reset projectfase on selecting other project
      ctx.state.selectedProjectFase = null;
      
      if (!ctx.state.fromProject) {
        // we have both employee and project here, check for dimension1
        const selectedEmp = ctx.state.employees?.filter(x => {
          return x.Medewerker === ctx.state.currentEmployee
        })
        if (selectedEmp && selectedEmp.length > 0 && selectedEmp[0].Dim1) {
          ctx.state.dimension1 = selectedEmp[0].Dim1
        } else { // no dimension on employee found, check project
          const selectedPrj = ctx.state.projects?.filter(x => {
            x.ProjectCode === ctx.state.selectedProject
          })
          if (selectedPrj && selectedPrj.length > 0 && selectedPrj[0].Dim1) {
            ctx.state.dimension1 = selectedPrj[0].Dim1
          }
        }
        return;
      } else {
        ctx.state.loading++
        ctx.state.disabledDates = []
        // reset dates when necessary
        if (payload.resetDates) {
          ctx.state.newDates = [] as Array<DateModel>;
        }
        ctx.state.error = '';
        const res = await axios.get(`${API_URL}/getNacalculatie?project=${encodeURIComponent(payload.project)}`)
        ctx.state.nacalculatie = res.data;
        handleNacalculatie(ctx, payload);
        ctx.state.disabledDates = [] // disable disabled dates (object is all disabled, array is none disabled)
        ctx.state.loading--
      }
    },
    async setItem(ctx, payload) {
      ctx.state.selectedItem = payload
    },
    async setProjectFase(ctx, payload) {
      ctx.state.selectedProjectFase = payload;
    },
    async setDescription(ctx, payload) {
      ctx.state.description = payload
    },
    async setEmployee(ctx, payload) {
      ctx.state.currentEmployee = payload.employee
      if (ctx.state.fromProject) {
        // we have both employee and project here, check for dimension1
        const selectedEmp = ctx.state.employees?.filter(x => {
          return x.Medewerker === ctx.state.currentEmployee
        })
        if (selectedEmp && selectedEmp.length > 0 && selectedEmp[0].Dim1) {
          ctx.state.dimension1 = selectedEmp[0].Dim1
        } else { // no dimension on employee found, check project
          const selectedPrj = ctx.state.projects?.filter(x => {
            x.ProjectCode === ctx.state.selectedProject
          })
          if (selectedPrj && selectedPrj.length > 0 && selectedPrj[0].Dim1) {
            ctx.state.dimension1 = selectedPrj[0].Dim1
          }
        }
        return;
      }
      ctx.state.loading++
      ctx.state.disabledDates = []
      // reset dates when necessary
      if (payload.resetDates) {
        ctx.state.newDates = [] as Array<DateModel>;
      }
      ctx.state.error = '';
      
      const res = await axios.get(`${API_URL}/getNacalculatie?employee=${encodeURIComponent(payload.employee)}`)
      ctx.state.nacalculatie = res.data;
      handleNacalculatie(ctx, payload);

      ctx.state.disabledDates = [] // disable disabled dates (object is all disabled, array is none disabled)
      ctx.state.loading--
    },
    async saveToAFAS(ctx, payload) {
      if (ctx.state.newDates) {
        ctx.state.disabledDates = []
        const promises: Array<Promise<void>> = [];
        for(const newDate of ctx.state.newDates.filter(x => {return x.status === Status.init || x.status === Status.error})) {
          promises.push((async () => {
            newDate.status = Status.loading
            try{
              const res = await axios.post(`${API_URL}/saveToAFAS`, 
              {
                date: newDate.date, 
                projectId: ctx.state.selectedProject, 
                itemcode: ctx.state.selectedItem,
                employeeId: ctx.state.currentEmployee,
                description: ctx.state.description,
                begintime: payload.begintime,
                endtime: payload.endtime,
                projectFase: ctx.state.selectedProjectFase,
                dim1: ctx.state.dimension1
              })
              if (res.status == 201) {
                newDate.status = Status.saved
              }
            } catch(exc:any) {
              newDate.error = exc.response.data.message as string
              newDate.status = Status.error
            }
          })().catch((e: any) => {
            console.error(`Unhandled error: ${e.message}`)
          }));
        }
        await Promise.all(promises);
      }
      if (!ctx.state.fromProject) {
        this.dispatch('setEmployee', {employee: ctx.state.currentEmployee, resetDates: false}) // force refresh of calendar
      } else {
        this.dispatch('setProject', {project: ctx.state.selectedProject, resetDates: false}) // force refresh of calendar
      }
    },
    async deleteNacalc(ctx, payload) {
      try{
        const res = await axios.post(`${API_URL}/deleteFromAFAS`, payload)
        if (res.status == 200) {
          // delete nacalc from local store
          ctx.state.datePopupDates = ctx.state.datePopupDates.filter(x => {
            return (x.Regelnummer !== payload.Regelnummer)
          });
          ctx.state.nacalculatie = ctx.state.nacalculatie.filter(x => {
            return (x.Regelnummer !== payload.Regelnummer)
          });

          if (ctx.state.datePopupDates && ctx.state.datePopupDates.length > 0) {
            // update amount in calender
            let dateInCalendar = ctx.state.attrs.filter(x => {
              return x.key === payload.Datum.split('T')[0]
            })
            const newAmount = ctx.state.datePopupDates.reduce((prev, curr) => {
              return {
                Aantal: prev.Aantal + curr.Aantal
              }
            })
            if (dateInCalendar && dateInCalendar.length > 0) {
              dateInCalendar[0].amount += newAmount.Aantal
              dateInCalendar[0].highlight.style.background = dateInCalendar[0].amount > 4 ? blue : orange
              dateInCalendar[0].highlight.style['background-color'] = dateInCalendar[0].amount > 4 ? blue : orange
            }
          } else { // no rows left, filter in calendar
            ctx.state.attrs = ctx.state.attrs.filter(x => {
              return x.key !== payload.Datum.split('T')[0]
            })
            // hide edit screen
            ctx.state.showDatePopup = false
          }
        } else {
          ctx.state.snackbarMessage = 'Er is iets fout gegaan, probeer het later opnieuw'
          ctx.state.showSnackbar = true
        }
      } catch(exc:any) {
        ctx.state.snackbarMessage = exc.response.data.message as string
        ctx.state.showSnackbar = true
      } finally {
        ctx.state.deleteOrEditLoading = false
      }
    },
    async updateNacalc(ctx, payload) {
      try {
        let dimension1 = null;
        const selectedEmp = ctx.state.employees?.filter(x => {
          return x.Medewerker === payload.MedewerkerCode
        })
        if (selectedEmp && selectedEmp.length > 0 && selectedEmp[0].Dim1) {
          dimension1 = selectedEmp[0].Dim1
        } else { // no dimension on employee found, check project
          const selectedPrj = ctx.state.projects?.filter(x => {
            x.ProjectCode === payload.ProjectId
          })
          if (selectedPrj && selectedPrj.length > 0 && selectedPrj[0].Dim1) {
            dimension1 = selectedPrj[0].Dim1
          }
        }
        if (dimension1) { // add to payload
          payload.dim1 = dimension1;
        }

        const res = await axios.post(`${API_URL}/updateInAFAS`, payload)
        ctx.state.showNacalcUpdatePopup = false
        if (!ctx.state.fromProject) {
          this.dispatch('setEmployee', {employee: payload.MedewerkerCode, resetDates: false, filterDates: payload.Datum}) // force refresh of calendar
        } else {
          this.dispatch('setProject', {project: payload.ProjectId, resetdates: false, filterDates: payload.Datum})
        }
        
      } catch (e: any) {
        ctx.state.snackbarMessage = e.response.data.message as string
        ctx.state.showSnackbar = true
      } finally {
        ctx.state.deleteOrEditLoading = false
        ctx.state.showNacalcUpdatePopup = false
      }
    },
    async setSnackbar(ctx, bool) {
      ctx.state.showSnackbar = bool;
    },
    async setShowDatePopup(ctx, bool) {
      ctx.state.showDatePopup = bool;
    },
    async setShowNacalcUpdatePopup(ctx, bool) {
      ctx.state.showNacalcUpdatePopup = bool;
    },
    async sendOTPCode(ctx, code) {
      ctx.state.loading++;

      try {
        console.log('activate otp')
        const res = await axios.post(`${API_URL}/activateOTP`, { code: code})
        console.log('activated otp')
        ctx.state.showOTPCodePopup = false;
        if (!ctx.state.employees) {  
          this.dispatch('getEmployees')
        }
        if (!ctx.state.projects) {
          this.dispatch('getProjects')
        }
        if (!ctx.state.items) {
          this.dispatch('getItems')
        }
        
      } catch (e: any) {
        ctx.state.snackbarMessage = e.response.data.message as string
        ctx.state.showSnackbar = true
      } finally {
        ctx.state.loading--;
      }

    },


    async settingsLogin(ctx, payload) {
      ctx.state.loading++
      try {
        const response = await axios.post(`${API_URL}/settingslogin`, payload);
        ctx.state.settingsAccessToken = response.data
      } catch (e: any) {
          console.error(e)
        	ctx.state.error = e
      } finally {
        ctx.state.loading--
      }
    },
    async settingsLoadInstances(ctx) {
      ctx.state.loading++
      try {
        const response = await axios.post(`${API_URL}/settingsloadinstances`, { accessToken: ctx.state.settingsAccessToken })
        ctx.state.instances = response.data
      } catch (e: any) {
        ctx.state.error = e
      } finally {
        ctx.state.loading--
      }
    },
    async settingsSaveInstance(ctx, instance) {
      ctx.state.loading++;
      try{
        const response = await axios.post(`${API_URL}/settingssaveinstance`, _.cloneDeep(instance), {
          headers: {
            accessToken: ctx.state.settingsAccessToken.accessToken }
          }
        )
      } catch (e: any) {
        ctx.state.error = e;
      } finally {
        ctx.state.loading--;
      }
    },
    setError(ctx, error) {
      ctx.state.error = error
    }
    
  },
  modules: {
  }
})

function handleNacalculatie(ctx: any, payload: any) {
  ctx.state.attrs = []
  if (ctx.state.nacalculatie && ctx.state.nacalculatie.length > 0) {
    const timeFromAmount = ctx.state.instances;
    for (let nacalc of ctx.state.nacalculatie) {
      const existing = ctx.state.attrs.filter((x: any) => {
        return x.key === nacalc.Datum.split('T')[0];
      });
      let colorToSet = blue;
      if (existing && existing.length > 0) {
        existing[0].amount += nacalc.Aantal;
        if (existing[0].amount >= ctx.state.timeFromError) {
          colorToSet = red;
        } else if (existing[0].amount <= ctx.state.timeToPartial) {
          colorToSet = orange;
        }
        existing[0].highlight.style.background = colorToSet;
        existing[0].highlight.style['background-color'] = colorToSet;
      } else {
        if (nacalc.Aantal >= ctx.state.timeFromError) {
          colorToSet = red;
        } else if (nacalc.Aantal <= ctx.state.timeToPartial) {
          colorToSet = orange;
        }
        ctx.state.attrs.push({
          key: DateTime.fromISO(nacalc.Datum).toFormat('yyyy-MM-dd'),
          highlight: {
            style: {
              background: colorToSet,
              'background-color': colorToSet
            },
            contentStyle: {
              color: 'White'
            },
            fillMode: 'solid'
          },
          dates: DateTime.fromISO(nacalc.Datum).toJSDate(),
          amount: nacalc.Aantal,
          hasnacalc: true
        });
      }
    }
  }

  // set colors for specific itemcodes
  setColorsForItemCodes(ctx.state);

  if (payload.resetDates === false) {
    const errorDates = ctx.state.newDates.filter((x: any) => { return x.status === Status.error; });
    for (let errorDate of errorDates) {
      ctx.state.attrs.push({
        key: DateTime.fromJSDate(errorDate.date).toFormat('yyyy-MM-dd'),
        highlight: {
          style: {
            background: green,
            'background-color': green
          },
          contentStyle: {
            color: 'White'
          },
          fillMode: 'solid'
        },
        dates: errorDate.date
      });
    }
  }

  if (payload.filterDates) {
    ctx.state.datePopupDates = ctx.state.nacalculatie?.filter((x: any) => {
      return (DateTime.fromISO(x.Datum).year === DateTime.fromISO(payload.filterDates).year
        && DateTime.fromISO(x.Datum).month === DateTime.fromISO(payload.filterDates).month
        && DateTime.fromISO(x.Datum).day === DateTime.fromISO(payload.filterDates).day);
    });
  }
}

function setColorsForItemCodes(state: any) {
  if (state.colors && state.colors.length > 0) {
    for(const date of state.attrs) {
      // only for full days, not less than 4 hours
      if (date.highlight.style.background === orange) {
        continue;
      }
      const nacalcs = state.nacalculatie.filter((x: any) => {
        return date.key === x.Datum.split('T')[0]
      })
      // group by itemcode on date
      if (nacalcs && nacalcs.length > 0) {
        let group = {} as any;
        for(const nacalc of nacalcs) {
          if (group[nacalc.Itemcode]) {
            group[nacalc.Itemcode].rows.push(nacalc)
          } else {
            group[nacalc.Itemcode] = { rows: [], itemcode: nacalc.Itemcode };
            group[nacalc.Itemcode].rows.push(nacalc);
          }
        }
        // calculate totals per itemcode, and set highest one
        let selectedgroup = null as any;
        for (let groupitem in group) {
          group[groupitem].total = 0
          for(let groupitemrow of group[groupitem].rows) {
            group[groupitem].total += groupitemrow.Aantal
          }
          if (!selectedgroup) {
            selectedgroup = group[groupitem]
          } else if (group[groupitem].total > selectedgroup.total ) {
            selectedgroup = group[groupitem]
          }
        }
        const color = state.colors.filter((x: any) => {
          return x.itemcode === selectedgroup.itemcode
        })
        if (color && color.length > 0) {
          date.highlight.style.background = color[0].color
          date.highlight.style['background-color'] = color[0].color;
        }

      }
    }
  }
}

export default store
